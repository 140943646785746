import React, { useEffect, useState } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Prism from 'prismjs';
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-python'

export default function Snippet(props) {
	const [copied, setCopied] = useState(false);

	useEffect(() => {
	  Prism.highlightAll();
	}, []);

	function description() {
		if (props.snippet.description) {
			return <p>{props.snippet.description}</p>
		} else {
			return null;
		}
	}

	function notes() {
		if (props.snippet.notes) {
			return <p><small>Note: {props.snippet.notes}</small></p>
		} else {
			return null;
		}
	}

	function url() {
		if (props.snippet.external_url) {
			return (
				<p className="Snippet-url">
					<a href={props.snippet.external_url} target="_blank">More info</a>
				</p>
			)
		} else {
			return null;
		}
	}

	function copy() {
		let label = "Copy code";
		if (copied) {
			label = "Code copied";
		} 

		if (props.snippet.language !== 'shortcut') {
			return (
				<CopyToClipboard text={props.snippet.snippet}
	        onCopy={() => setCopied(true)}>
	        <button className="btn btn-sm btn-branding-outline ms-2">{label}</button>
	      </CopyToClipboard>
			)
		}
	}

	function comment() {
		let label = "Comment";

		if (props.snippet.comments_count === 1) {
			label = "1 comment";
		} 

		if (props.snippet.comments_count > 1) {
			label = `${props.snippet.comments_count} comments`;
		}

		return (
       <a className="btn btn-sm btn-branding-outline" href={`${props.snippet.url}#comments`}>{label}</a>
		)
	}

	function link() {
		return (
			<a className="btn btn-sm btn-branding-outline" href={props.snippet.url} target="_blank">
				<i className="fas fa-link me-0"></i>
			</a>
			
		)
	}

	function header() {
		return (
			<div className="Snippet-header d-flex justify-content-between">
				<h3>
					<a href={props.snippet.url} target="_blank">
						{props.snippet.name}
					</a>
				</h3>
				<div>
				{link()}
				{copy()}
				</div>
			</div>
		);
	}

	function code() {
		if (props.snippet.snippet) {
			return (
				<pre className="Snippet-code"><code className={`language-${props.snippet.language}`}>{props.snippet.snippet}
				</code></pre>
			)
		}
	}

	function macShortcut() {
		if (props.snippet.mac_shortcut) {
			return (
				<div className="Snippet-shortcut Snippet-shortcut--mac">
					{props.snippet.mac_shortcut}
				</div>
			)
		}
	}

	function windowsShortcut() {
		if (props.snippet.mac_shortcut) {
			return (
				<div className="Snippet-shortcut Snippet-shortcut--windows">
					{props.snippet.windows_shortcut}
				</div>
			)
		}
	}

	function cheatsheet() {
		return (
			<div className="Snippet-cheatsheet">
					<div className="d-flex justify-content-between">
						<div>
							{url()}
						</div>
						<a href={props.snippet.snippet_cheatsheet_url} className="d-flex" target="_blank">
							<img src={props.snippet.cheatsheet_logo_url} />
							<span className="text-muted">
								{props.snippet.cheatsheet_name}
							</span>
						</a>
					</div>
			</div>
		)
	}

	function shortcuts() {
		if (props.snippet.language === 'shortcut') {
			return (
				<div className="Snippet-shortcuts">
					{windowsShortcut()}			
					{macShortcut()}			
				</div>
			)
		}
	}

	return (
		<div className={`Snippet Snippet--${props.theme} border`}>
			{header()}
			{code()}
			{shortcuts()}
			
			<div className="Snippet-footer">
				{description()}
				{notes()}
				{cheatsheet()}
			</div>
		</div>
	)
}